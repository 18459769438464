import { useContext, useEffect, useState } from "react";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Home from "../pages/Home";
import { AuthContext } from "../AppContext";

const Protected = () => {
    const { permission, setPermission } = useContext(AuthContext)
    const navigate = useNavigate();
    let Auth = {
        token: Cookies.get("fmljwt")
    }
    return (
        Auth.token ? <Navigate to={"/home"} /> : <Outlet />
    )
}
export default Protected