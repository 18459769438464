import Cookies from "js-cookie";
import { useContext, useEffect, useState } from "react";
import { Outlet, Navigate, useNavigate } from "react-router-dom";
import { AuthContext } from "../AppContext";

const PrivetRoutes = () => {
    const { permission, setPermission } = useContext(AuthContext)
    const navigate = useNavigate();
    let Auth = {
        token: Cookies.get("fmljwt")
    }
    return (
        Auth.token ? <Outlet /> : <Navigate to="/" />
    )
}
export default PrivetRoutes