import React, { useState } from "react";
import { Card, Col, Form, Row, Button } from "react-bootstrap";
import { SelectPicker } from "rsuite";
import Layout from '../../layout/Layout';
import { Link } from "react-router-dom";
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { python } from '@codemirror/lang-python';
import { okaidia } from '@uiw/codemirror-theme-okaidia';

const dataType = [
    'cUrl', 'c', 'c++', 'python'
].map(item => ({ label: item, value: item }));

const RcExchangeReportView = () => {
    const [selectedLang, setSelectedLang] = useState('cUrl');
    const [url, setUrl] = useState('https://rcdetailsnode.rto./api/rc_search');
    const [clientid, setClientid] = useState('358778d7-6c9b-9c10-9e3f-557f60569547');
    const [clientsecret, setClientsecret] = useState('896e5d56a6f0d256e1ad66');
    const [regNumber, setRegNumber] = useState('BR06BR6348');
    const [theme, setTheme] = useState(okaidia); // Dummy theme value for now

    const keyval = `curl --location '${url}'
--header 'clientid: ${clientid}'
--header 'clientsecret: ${clientsecret}'
--header 'Content-Type: application/json'
--data '{
    "reg_number": "${regNumber}"
}'`;

    const codeSnippets = {
        cUrl: keyval,
        c: `#include <stdio.h>
int main() {
   printf("Hello, World!");
   return 0;
}`,
        'c++': `#include <iostream>
using namespace std;
int main() {
   cout << "Hello, World!";
   return 0;
}`,
        python: `import requests
url = '${url}'
headers = {
   'clientid': '${clientid}',
   'clientsecret': '${clientsecret}',
   'Content-Type': 'application/json'
}
data = {
   "reg_number": "${regNumber}"
}
response = requests.post(url, headers=headers, json=data)
print(response.json())`
    };

    return (
        <Layout sidebar={true}>
            <div className="page-heading backbtn">
                <h3>
                    <Link to="/rc-exchange-report" className='btn btn-transparent btn-icon me-2'>
                        <i className='bx bx-chevron-left'></i>
                    </Link>
                    Stephanie J. Chapman
                </h3>
                <div className="page-heading-right">
                    <Link to="/rc-exchange-report/carinfo-rc-count" className="btn btn-icon btn-transparent btn-danger">
                        <i className='bx bx-edit-alt'></i>
                    </Link>
                </div>
            </div>
            <div className='page-content'>
                <Card className="radiustop-0">
                    <Card.Body className="px-5 py-4">
                        <Row className="g-3">
                            <Col md={5}>
                                <Row className="g-3">
                                    <Form.Control
                                        type="text"
                                        value="Type: Monthly"
                                        disabled
                                    />
                                    <Form.Control
                                        type="text"
                                        value="Limit: 9,050"
                                        disabled
                                    />
                                    <Form.Control
                                        type="text"
                                        value="Clientid: 358778d7-6c9b-9c10-9e3f-557f60"
                                        disabled
                                    />
                                    <Form.Control
                                        type="text"
                                        value="Clientsecretid: 896e56a6f0d25e1ad66"
                                        disabled
                                    />
                                </Row>
                            </Col>
                            <Col md={1}></Col>
                            <Col md={6}>
                                <div className="d-flex align-items-center justify-content-between mb-3">
                                    <SelectPicker
                                        cleanable={false}
                                        data={dataType}
                                        searchable={false}
                                        placeholder="Select Language"
                                        value={selectedLang}
                                        onChange={setSelectedLang}
                                    />
                                    <Button className="btn btn-icon btn-transparent" onClick={() => navigator.clipboard.writeText(codeSnippets[selectedLang])}>
                                        <i className='bx bx-copy-alt'></i>
                                    </Button>
                                </div>
                                <div className='mb-4'>
                                    <CodeMirror
                                        value={codeSnippets[selectedLang]}
                                        theme={theme}
                                        extensions={[selectedLang === 'python' ? python() : javascript()]}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default RcExchangeReportView;
