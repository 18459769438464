import React, { useContext, useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Form, Row } from "react-bootstrap";
import { SelectPicker } from "rsuite";
import Layout from '../../layout/Layout';
import { Link, useNavigate, useParams } from "react-router-dom";
import { getServices, serviceUpdate } from "./service";
import { toast } from "react-toastify";
import CodeMirror from '@uiw/react-codemirror';
import { javascript } from '@codemirror/lang-javascript';
import { okaidia } from "@uiw/codemirror-theme-okaidia";
import { AuthContext } from "../../AppContext";


const RcServicesAdd = () => {
    const { id } = useParams();
    const navigate = useNavigate()
    const [loading, setIsLoading] = useState(false)
    const [services, setServices] = useState({ name: "", status: 0 })
    const [validated, setValidated] = useState(false);
    const [theme, setTheme] = useState(okaidia); // Dummy theme value for now
    const { token } = useContext(AuthContext)

    const fetchService = async () => {
        setIsLoading(true)
        try {
            const formData = new FormData()
            formData?.append("id", id)
            const response = await getServices(formData, token)
            if (response?.status) {
                setServices(response?.data)
            }
        } catch (error) {
            console.log('error', error)
        } finally {
            setIsLoading(false)
        }
    }

    /** handle Change input values*/
    const handleChange = (e, field) => {
        setServices((prev) => ({
            ...prev,
            [field]: e,
        }));
    }

    /**Save the service data  */
    const handleSubmit = async (event) => {
        setValidated(true)
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            setValidated(true);
            try {
                const formData = new FormData()
                id && formData?.append("id", id)
                formData?.append("name", services?.name)
                formData?.append("api_endpoint", services?.api_endpoint)
                formData?.append("body", services?.body)
                formData?.append("header", services?.header)
                formData?.append("status", services?.status)
                const response = await serviceUpdate(formData, token)
                if (response?.status) {
                    if (id) {
                        toast.success("Services Update successfully");
                        navigate(`/rc-services/view/${id}`)
                    } else {
                        toast.success("Services Add successfully");
                        navigate(`/rc-services`)

                    }
                }
            } catch (error) {
                console.error('An error occurred:', error);
            }
        }
    };

    useEffect(() => {
        fetchService()
    }, [])
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>RC Services {id ? "Edit" : "Add"}</h3>
                <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                    <Breadcrumb.Item >
                        <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >
                        <Link to="/rc-services">RC Services</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>RC Services {id ? "Edit" : "Add"}</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="page-content">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Card className="mb-4 radiustop-0">
                        <Card.Body>
                            <Row>
                                <Col md={6}>
                                    <Form.Label>Service Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        className="my-2"
                                        onChange={(e) => handleChange(e?.target?.value, "name")}
                                        value={services?.name}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Service Name Field Is Require
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={4}>
                                    <Form.Label>API End Point</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="api_endpoint"
                                        className="my-2"
                                        onChange={(e) => handleChange(e?.target?.value, "api_endpoint")}
                                        value={services?.api_endpoint}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Service Name Field Is Require
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={2}>
                                    <Form.Label htmlFor="status" className="d-block mb-2">
                                        Status
                                    </Form.Label>

                                    <Form.Check
                                        type="switch"
                                        onChange={(e) => handleChange(e?.target?.checked ? 1 : 0, "status")}
                                        name="status"
                                        checked={services?.status === 1 ? true : false}
                                    />
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Header</Form.Label>
                                    <CodeMirror
                                        value={services?.header ? services?.header : ""}
                                        theme={theme}
                                        onChange={(e) => handleChange(e, "header")}
                                        extensions={javascript()}
                                        height="250px"
                                    />
                                </Col>
                                <Col md={6}>
                                    <Form.Label>Body</Form.Label>
                                    <CodeMirror
                                        height="250px"
                                        theme={theme}
                                        extensions={javascript()}
                                        onChange={(e) => handleChange(e, "body")}
                                        value={services?.body ? services?.body : ""}
                                    />
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className="text-end">
                            <Button variant="primary" type="submit" className="me-3">Save</Button>
                            <Link to='/rc-services'>
                                <Button variant="secondary">Cancel</Button>
                            </Link>
                        </Card.Footer>
                    </Card>
                </Form>
            </div>
        </Layout>
    )
}

export default RcServicesAdd