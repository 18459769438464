import Cookies from 'js-cookie'
import { API } from '../../App';

export const getPartnerData = async (payload,token) => {
      let result
      try {
            result = await API.post("/get_partner_service_data", payload, { headers: { authorization: `${token}` } })

      } catch (error) {
            console.log(error)
      }
      return result?.data
}
export const partnerUpdate = async (payload,token) => {
      let result
      try {
            result = await API.post("/store_partner_service_data", payload, { headers: { authorization: `${token}` } })

      } catch (error) {
            console.log(error)
      }
      return result?.data
}
export const deletePartner = async (payload,token) => {
      let result
      try {
            result = await API.post("/delete_partner_service_data", payload, { headers: { authorization: `${token}` } })

      } catch (error) {
            console.log(error)
      }
      return result?.data
}
