import React, { useContext } from 'react';
import { Link, NavLink } from 'react-router-dom'
import Logo from '../Component/Logo';
import LogoMini from '../Component/Logo-mini';
import { AuthContext } from '../AppContext';

const Sidebar = ({ sidebar }) => {

    const { classActive, setClassActive, userPermission } = useContext(AuthContext)
    const roleName = localStorage.getItem('role') ? localStorage.getItem('role') : ""
    return (
        <>
            <div className={sidebar ? "sidebar-wrapper active" : "sidebar-wrapper"}>
                <div className="sidebar-header">
                    <div className="d-flex justify-content-between">
                        <div className='sidebar-logo'>
                            <Link to="/Home">
                                <Logo />
                                <LogoMini />
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="sidebar-menu">
                    <ul className="menu">
                        <li className="sidebar-item">
                            <NavLink to="/Home" onClick={() => { setClassActive({ ...classActive, drp1: false }) }} className='sidebar-link'>
                                <i className='bx bxs-home'></i>
                                <span>Dashboard</span>
                            </NavLink>
                        </li>
                        {/* <li className="sidebar-item">
                            <NavLink to="/rc-exchange-report" className='sidebar-link'>
                                <i className='bx bxs-credit-card-front'></i>
                                <span>RC Exchange Report</span>
                            </NavLink>
                        </li> */}
                         {userPermission["RC-Services"]?.view == 1 &&
                        <li className="sidebar-item" >
                            <NavLink to="/rc-services" onClick={() => { setClassActive({ ...classActive, drp1: false }) }} className='sidebar-link'>
                                <i className='bx bxs-cog'  ></i>
                                <span >RC Services</span>
                            </NavLink>
                        </li>
                            }
                        {userPermission["RC-Partner"]?.view == 1 &&

                            <li className="sidebar-item" >
                                <NavLink to="/rc-partner" onClick={() => { setClassActive({ ...classActive, drp1: false }) }} className='sidebar-link'>
                                    <i className='bx bxs-user' ></i>
                                    <span>RC Partner</span>
                                </NavLink>
                            </li>}
                        {roleName == "ADMIN" ? <li className="sidebar-item has-sub">
                            <div onClick={() => { setClassActive({ ...classActive, drp1: true }) }} className="sidebar-link">
                                <i className='bx bxs-lock-open-alt' ></i>
                                <span>User Permission</span>
                            </div>
                            <ul className={`${"submenu"} ${classActive.drp1 ? "active" : "inactive"}`} >
                                <li className="submenu-item">
                                    <NavLink to="/module">
                                        <span>Module</span>
                                    </NavLink>
                                </li>
                                <li className="submenu-item">
                                    <NavLink to="/role">
                                        <span>Role</span>
                                    </NavLink>
                                </li>
                                <li className="submenu-item">
                                    <NavLink to="/user">
                                        <span>Permission</span>
                                    </NavLink>
                                </li>
                            </ul>
                        </li> : ""}
                    </ul>
                </div>
            </div>
        </>
    )
}

export default Sidebar