import React, { useContext, useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { SelectPicker } from "rsuite";
import { API } from "../../App";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import Layout from '../../layout/Layout';
import dayjs from "dayjs";
import Cookies from "js-cookie";
import Pagination from "rc-pagination";
import { Link, useLocation, useParams } from "react-router-dom";
import moment from "moment";
import { getServices } from "../RcServices/service";
import { AuthContext } from "../../AppContext";

var Page_array = [];
const dateOptions = ['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Yearly'].map(
  item => ({ label: item, value: item?.toLowerCase() })
);
const CarinfoRcCount = () => {
  const loction = useLocation()
  const [Data, setData] = useState([])
  const [size, setSize] = useState(10);
  const [current, setCurrent] = useState(1);
  const [PageHook, setPageHook] = useState([])
  const [loading, setloading] = useState(true)
  const [dateTimezone, setDateTimezone] = useState("daily");
  const [allServices, setAllServices] = useState([]);
  const [selectedServices, setSelectedServices] = useState(loction?.state?.service_id);
  const {token}=useContext(AuthContext)
  const [state, setState] = useState({
    start: moment().subtract(6, 'days'),
    end: moment(),
  });
  const { start, end } = state;
  const handleCallback = (start, end) => {
    setState({ start, end });
    setCurrent(1);
  };

  const label = start.format('DD-MM-YYYY') + ' - ' + end.format('DD-MM-YYYY');
  const GetData = async () => {
    const Form = new FormData();
    Form.append("startDate", start.format('YYYY-MM-DD'))
    Form.append("endDate", end.format('YYYY-MM-DD'))
    Form.append('type', dateTimezone)
    Form.append('partner_id', loction?.state?.id)
    Form.append('service_id', selectedServices)
    Form.append('page', current)
    Form.append('limit', size)
    const result = await API.post("/get_partner_request_count", Form, { headers: { authorization: `${token}` } })
    setData(result.data)
    PageGetData()
    setloading(false)
  }

  const fetchServices = async () => {
    setloading(true);
    try {
      const response = await getServices({},token);
      if (response?.status) {
        const transformData = (data) => {
          return data?.map(item => ({
            value: item?.id,
            label: item?.name
          }));
        };

        setAllServices([...allServices, ...transformData(response?.data)]);
      } else {
        setAllServices([]);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setloading(false);
    }
  };

  const paginationData = (page, pageSize) => {
    setCurrent(page);
    setSize(pageSize);
  };


  const PageGetData = async () => {
    var PageNumber = [10, 25, 50, 100]
    Page_array = []
    PageNumber.map((val, index) => {
      Page_array.push({ label: val, value: val })
    })
    setPageHook(Page_array)
  };

  const data = [{ label: "Select Type", value: "" }, { label: "RTO to CarInfo Type", value: 0 }, { label: "CarInfo to RTO Type", value: 1 }]

  useEffect(() => {
    GetData()
  }, [state, current, size, dateTimezone,selectedServices])
  useEffect(() => {
    fetchServices()
  }, [])
  return (
    <Layout sidebar={true}>
      <div className="page-heading backbtn">
        <h3 className="my-1">
          <Link to="/rc-partner" className='btn btn-transparent btn-icon me-2'>
            <i className='bx bx-chevron-left' ></i>
          </Link>
          RC {loction?.state?.partner} Count
        </h3>
        <div className="page-heading-right">
          <SelectPicker
            cleanable={false}
            data={dateOptions}
            searchable={false}
            value={dateTimezone}
            onChange={(e) => { setDateTimezone(e); setCurrent(1); }}
            placement="bottomEnd"
            placeholder="Select Date"
            className="wv-150 my-1 ms-3"
          />
          <DateRangePicker
            initialSettings={{
              startDate: start.toDate(),
              endDate: end.toDate(),
              ranges: {
                Today: [moment().toDate(), moment().toDate()],
                Yesterday: [
                  moment().subtract(1, 'days').toDate(),
                  moment().subtract(1, 'days').toDate(),
                ],
                'Last 7 Days': [
                  moment().subtract(6, 'days').toDate(),
                  moment().toDate(),
                ],
                'Last 30 Days': [
                  moment().subtract(29, 'days').toDate(),
                  moment().toDate(),
                ],
                'This Month': [
                  moment().startOf('month').toDate(),
                  moment().toDate(),
                ],
                'Last Month': [
                  moment().subtract(1, 'month').startOf('month').toDate(),
                  moment().subtract(1, 'month').endOf('month').toDate(),
                ],
              },
            }}
            onCallback={handleCallback}
          >
            <div
              id="reportrange"
              className="form-control wv-225 my-1 ms-3 "
              style={{
                cursor: 'pointer',
              }}
            >
              <i className="fa fa-calendar"></i>&nbsp;
              <span>
                {label}
              </span> <i className="fa fa-caret-down"></i>
            </div>
          </DateRangePicker>
          <SelectPicker
            cleanable={false}
            data={allServices}
            searchable={false}
            value={selectedServices}
            onChange={(e) => { setSelectedServices(e); setCurrent(1) }}
            className="wv-150 my-1 ms-3"
            placeholder="Select Services"
          />
          <SelectPicker
            cleanable={false}
            data={PageHook}
            searchable={false}
            value={size}
            className="wv-100 my-1 ms-3"
            onChange={(e) => {
              setSize(Number(e));
              setCurrent(1);
            }}
          />
        </div>
      </div>
      <div className="page-content">
        <Card className="radiustop-0">
          <Card.Body>
            <Table className="table-bg-primary" striped bordered responsive>
              <thead>
                <tr className="text-center">
                  <th rowSpan={2}>No</th>
                  <th rowSpan={2}>Date</th>
                  <th colSpan={3}>Vender Exchange Report</th>
                </tr>
                <tr className="text-center">
                  <th>Success</th>
                  <th>Fail</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {
                  Data?.data?.length > 0 ? Data?.data?.map((val, i) => {
                    return (
                      <tr key={i} className="text-center">
                        <td width="5%"> {(current === 1) ? i + 1 : current * size + i + 1 - size} </td>
                        <td width="17%">{val.date_range}</td>
                        <td width="13%">{val.request_success_count}</td>
                        <td width="13%">{val.request_failed_count}</td>
                        <td width="13%">{val.request_success_count + val.request_failed_count}</td>
                      </tr>
                    )
                  }) : <tr>
                    <td colSpan="100%" className="p-0">
                      <div className='no-found'>
                        <img src="../../not-found/image.svg" />
                        <p>No Found Carinfo RC Count</p>
                      </div>
                    </td>
                  </tr>
                }
                {
                  Data?.total?
                    <tr className="text-center fw-600">
                      <td colSpan={2}>Total Record Of API Calling</td>
                      <td>{Data?.total?.total_success_count}</td>
                      <td>{Data?.total?.total_failed_count}</td>
                      <td>{Data?.total?.total_request_total}</td>
                    </tr> : ""
                }
              </tbody>

            </Table>
            {Data?.totalcount > size ? (
              <div className="pagination-custom">
                <Pagination
                  showTitle={false}
                  className="pagination-data"
                  onChange={paginationData}
                  total={Data?.totalcount}
                  current={current}
                  pageSize={size}
                  showSizeChanger={false}
                />
              </div>
            ) : (
              ""
            )}
          </Card.Body>
        </Card>
      </div>
    </Layout>
  )
}

export default CarinfoRcCount