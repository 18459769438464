import React, { useContext, useEffect, useState } from "react";
import { Breadcrumb, Button, Card, Col, Form, Row } from "react-bootstrap";
import { SelectPicker } from "rsuite";
import Layout from '../../layout/Layout';
import { Link, useNavigate, useParams } from "react-router-dom";
import { getServices } from "../RcServices/service";
import { toast } from "react-toastify";
import { getPartnerData, partnerUpdate } from "./service";
import { AuthContext } from "../../AppContext";

const dataType = ['Daily',"Weekly", "Monthly","Unlimite"].map(item => ({ label: item, value: item?.toLowerCase() }));

const RcPartnerAdd = () => {
    const {token}=useContext(AuthContext)
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setIsLoading] = useState(false);
    const [allServices, setAllServices] = useState([]);
    const [partner, setPartner] = useState({ name: "", status: 0, services: [] });
    const [validated, setValidated] = useState(false);

    const fetchPartner = async () => {
        setIsLoading(true);
        try {
            const formData = new FormData();
            formData.append("id", id);
            const response = await getPartnerData(formData,token);
            if (response?.status) {
                setPartner(response.data);
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false);
        }

    };

    const fetchServices = async () => {
        setIsLoading(true);
        try {
            const response = await getServices({},token);
            if (response?.status) {
                setAllServices(response.data);
            } else {
                setAllServices([]);
            }
        } catch (error) {
            console.log('error', error);
        } finally {
            setIsLoading(false);
        }
    };

    /** handle Change input values*/
    const handleChange = (e, field) => {
        setPartner((prev) => ({
            ...prev,
            [field]: e,
        }));
    }


    const handleChangeService = (value, field, serviceIndex, serviceId) => {
        setPartner((prev) => {
            const newServices = [...prev.services];
            if (serviceIndex !== -1) {
                newServices[serviceIndex] = {
                    ...newServices[serviceIndex],
                    [field]: value
                };
            } else {
                newServices.push({
                    service_id: serviceId,
                    [field]: value
                });
            }
            return {
                ...prev,
                services: newServices
            };
        });
    };

    const handleSubmit = async (event) => {
        setValidated(true);
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() === false) {
            event.stopPropagation();
        } else {
            try {
                const hasValidService = partner?.services?.some(service =>
                    service.api_limit && service?.limit_type && service?.limit_type !== "" && service.api_limit != "");

                if (hasValidService||partner?.services?.some(service =>   service?.limit_type && service?.limit_type == "unlimite") ) {
                    const res = await partnerUpdate(partner,token); // Logic for updating the partner
                    if (id) {
                        toast.success('Partner updated successfully');
                        navigate(`/rc-partner/view/${id}`);
                    } else {
                        toast.success('Partner created successfully');
                        navigate('/rc-partner');
                    }
                } else {
                    toast.error('At least one service is required');
                }
            } catch (error) {
                console.log('error', error);
                toast.error('Failed to save partner');
            } finally {
                setIsLoading(false);
            }
        }
    };


    useEffect(() => {
        fetchServices();
        id && fetchPartner();
    }, [id]);
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>RC Partner Report {id ? "Edit" : "Add"}</h3>
                <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                    <Breadcrumb.Item>
                        <Link to="/"><i className='bx bx-home-alt me-2 fs-5'></i> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <Link to="/rc-partner">RC Partner Report</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>RC Partner Report {id ? "Edit" : "Add"}</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="page-content">
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <Card className="mb-4 radiustop-0">
                        <Card.Body>
                            <Row>
                                <Col md={4}>
                                    <Form.Label>Partner Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={partner?.name}
                                        onChange={(e) => handleChange(e?.target?.value, "name")}
                                        className="my-2"
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Partner Name Field Is Required
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={4}>
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="email"
                                        value={partner?.email}
                                        onChange={(e) => handleChange(e?.target?.value, "email")}
                                        className="my-2"
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Partner Name Field Is Required
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={3}>
                                    <Form.Label>Phone No.</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="phone"
                                        value={partner?.phone}
                                        onChange={(e) => handleChange(e?.target?.value, "phone")}
                                        className="my-2"
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Partner Name Field Is Required
                                    </Form.Control.Feedback>
                                </Col>
                                <Col md={1}>
                                    <Form.Label htmlFor="status" className="d-block mb-2">
                                        Status
                                    </Form.Label>
                                    <Form.Check
                                        type="switch"
                                        name="status"
                                        onChange={(e) => handleChange(e?.target?.checked ? 1 : 0, "status")}
                                        checked={partner?.status === 1}
                                    />
                                </Col>
                            </Row>
                            {allServices?.length > 0 && allServices?.map((item, index) => {
                                const serviceIndex = partner?.services?.findIndex((el) => el?.service_id === item?.id);
                                const data = serviceIndex !== -1 ? partner?.services?.[serviceIndex] : { service_id: item.id, limit_type: '', limit_count: '', status: 0 };

                                return (
                                    <Row className="my-3" key={index}>
                                        <Col md={4}>
                                            <Form.Label>Service Name</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="service_name"
                                                className="my-2"
                                                required
                                                disabled
                                                value={item?.name}
                                            />
                                        </Col>
                                        <Col md={4}>
                                            <Form.Label>Limit Type</Form.Label>
                                            <SelectPicker
                                                block
                                                cleanable={false}
                                                data={dataType}
                                                searchable={false}
                                                onChange={(e) => handleChangeService(e, "limit_type", serviceIndex, item.id)}
                                                value={data?.limit_type}
                                                placeholder="Select Type"
                                                className="my-2"
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Select Type Field Is Required
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label>Limit</Form.Label>
                                            <Form.Control
                                                type="text"
                                                name="api_limit"
                                                className="my-2"
                                                value={data?.api_limit}
                                                onChange={(e) => {
                                                    if (/^[0-9]*$/.test(e.target.value)) {
                                                        handleChangeService(e.target.value, "api_limit", serviceIndex, item.id)
                                                    }
                                                }}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Limit Field Is Required
                                            </Form.Control.Feedback>
                                        </Col>
                                        <Col md={1}>
                                            <Form.Label htmlFor="status" className="d-block mb-2">
                                                Status
                                            </Form.Label>
                                            <Form.Check
                                                type="switch"
                                                onChange={(e) => handleChangeService(e.target.checked ? 1 : 0, "status", serviceIndex, item.id)}
                                                checked={data?.status === 1}
                                            />
                                        </Col>
                                    </Row>
                                )
                            })}
                        </Card.Body>
                        <Card.Footer className="text-end">
                            <Button variant="primary" type="submit" className="me-3">Save</Button>
                            <Link to='/rc-partner'>
                                <Button variant="secondary">Cancel</Button>
                            </Link>
                        </Card.Footer>
                    </Card>
                </Form>
            </div>
        </Layout>
    );
};

export default RcPartnerAdd;
