import dayjs from "dayjs";
import Cookies from "js-cookie";
import Pagination from "rc-pagination";
import React, { useContext, useEffect, useState } from "react";
import { Button, Card, Form, InputGroup, Modal, Table } from "react-bootstrap";
import { SelectPicker } from "rsuite";
import Layout from '../../layout/Layout';
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { Link } from "react-router-dom";
import { deleteService, getServices, serviceUpdate } from "./service";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import $ from "jquery";
import { AuthContext } from "../../AppContext";


const pageLimit = [10, 25, 50, 100].map(
    item => ({ label: item, value: item })
);

const RcServices = () => {
    const [size, setSize] = useState(10);
    const [current, setCurrent] = useState(1);
    const [loading, setIsLoading] = useState(true)
    const [allServices, setAllServices] = useState([])
    const [searchService, setSearchService] = useState("")
    const { userPermission,token } = useContext(AuthContext)

    const paginationData = (page, pageSize) => {
        setCurrent(page);
        setSize(pageSize);
    };

    const fetchServices = async () => {
        setIsLoading(true)
        try {
            const formData = new FormData()
            formData?.append("page", current)
            formData?.append("limit", size)
            formData?.append("name", searchService?.trim())
            const response = await getServices(formData,token)
            if (response?.status) {
                setAllServices(response)
            } else {
                setAllServices([])
                toast?.error("Data not Found")
            }
        } catch (error) {
            console.log('error', error)
        } finally {
            setIsLoading(false)
        }
    }
    /**Status Change */
    const Togglechange = async (id, status) => {

        const Form = new FormData();
        Form.append("id", id);
        Form.append("status", status ? 1 : 0);
        try {

            const result = await serviceUpdate(Form,token);
            if (result) {
                toast.success("Status Update successfully");
                fetchServices();
            }
        } catch (error) {
            console.log('error', error)
        }
    };

    const handleSearch = () => {
        fetchServices();
        setCurrent(1);
    }

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            handleSearch()
        }
    };

    /**delete service*/
    let count = 10
    let swalCountdownInterval
    const handleDelete = async (id) => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-danger btn-lg counter",
                cancelButton: "btn btn-primary btn-lg me-3",
            },
            buttonsStyling: false,
        });

        swalWithBootstrapButtons
            .fire({
                title: "Are you sure you want to delete?",
                text: "You won't be able to revert this!",
                imageUrl: '../../icon/alert.svg',
                imageWidth: 80,
                imageHeight: 80,
                confirmButtonText: "OK (10)",
                cancelButtonText: "Cancel",
                showCancelButton: true,
                reverseButtons: true,
                didOpen: () => {
                    $(".swal2-confirm").attr('disabled', true);
                    swalCountdownInterval = setInterval(function () {
                        count--
                        if (count < 1) {
                            $(".counter").text(`OK`)
                            $(".swal2-confirm").attr('disabled', false);
                            clearInterval(swalCountdownInterval)
                        } else {
                            $(".counter").text(`OK (${count})`)
                        }
                    }, 1000);
                }
            })
            .then(async (result) => {
                try {

                    if (result.isConfirmed) {
                        const formData = new FormData()
                        formData?.append("id", id)
                        const deletedata = await deleteService(formData,token)
                        fetchServices()
                    } else {
                        count = 10
                        clearInterval(swalCountdownInterval)
                    }
                } catch (error) {
                    console.log('error', error)
                }
            });
    };

    useEffect(() => {
        fetchServices()
    }, [current, size])

    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3 className="my-1">RC Services <span></span></h3>
                <div className="page-heading-right">

                    <div className="inputgroup my-1">
                        <Form.Control
                            type="text"
                            name="searchvalue"
                            placeholder="Search Services"
                            className="wv-325 ms-3"
                            onChange={(e) => { e.target.value == "" && handleSearch(); setSearchService(e.target.value) }}
                            onKeyPress={handleKeyPress}
                        />
                        <i className="bx bx-search" onClick={handleSearch} />
                    </div>

                    <SelectPicker
                        cleanable={false}
                        data={pageLimit}
                        searchable={false}
                        onChange={(e) => { setSize(e); setCurrent(1) }}
                        value={size}
                        className="wv-100 my-1 ms-3"
                    />
                    {userPermission["RC-Services"]?.add == 1 &&
                        <Link to="/rc-services/add" className="btn btn-primary my-1 ms-3">Add New</Link>}
                </div>
            </div>
            <div className="page-content">
                <Card className="radiustop-0">
                    <Card.Body>
                        {loading && <div className="loader table-loader"></div>}
                        <Table className="table-bg-primary" bordered responsive striped>
                            <thead>
                                <tr>
                                    <th width="5%" className="text-center">No</th>
                                    <th width="45%" className="text-center">Services Name</th>
                                    <th width="20%" className="text-center">Status</th>
                                    <th width="10%" className="text-center">Show Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allServices?.data?.length > 0 ? allServices?.data?.map((item, index) => {
                                    return (<tr key={index}>
                                        <td className='text-center'>{(current === 1) ? index + 1 : current * size + index + 1 - size}</td>
                                        <td className="text-center">{item?.name}</td>
                                        <td className="text-center"> <Form.Check
                                            type="switch"
                                            onChange={(e) => {
                                                Togglechange(item?.id, e.target.checked);
                                            }} name="status"
                                            disabled={userPermission["RC-Services"]?.edit != 1}
                                            checked={item?.status === 1 ? true : false}
                                        /></td>
                                        <td>
                                            <div className='d-flex align-items-center justify-content-center'>
                                                {/* <Link to="/rc-services/carinfo-rc-count" className="btn btn-icon btn-transparent btn-danger">
                                                <i className='bx bx-receipt'></i>
                                            </Link> */}
                                                <Link to={`/rc-services/view/${item?.id}`} className="btn btn-icon btn-transparent btn-primary">
                                                    <i className='bx bx-show'></i>
                                                </Link>
                                                {userPermission["RC-Services"]?.delete == 1 && <><span className='border-start py-2 mx-2'></span>
                                                    <Button size="sm" variant="danger" className="btn-icon btn-transparent" onClick={() => handleDelete(item?.id)} >
                                                        <i className='bx bx-trash'></i>
                                                    </Button></>}
                                            </div>
                                        </td>
                                    </tr>)
                                }) :
                                    <tr>
                                        <td colSpan="100%" className="p-0">
                                            <div className='no-found'>
                                                <img src="../../not-found/image.svg" />
                                                <p>No Found RC Services</p>
                                            </div>
                                        </td>
                                    </tr>}
                            </tbody>
                        </Table>
                        {allServices?.totalcount > size && <div className="pagination-custom">
                            <Pagination
                                showTitle={false}
                                className="pagination-data"
                                onChange={paginationData}
                                total={allServices?.totalcount}
                                current={current}
                                pageSize={size}
                                showSizeChanger={false}
                            />
                        </div>}
                    </Card.Body>
                </Card>
            </div>
        </Layout>
    )
}

export default RcServices