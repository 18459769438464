import React from "react";
import { Breadcrumb, Button, Card, Col, Form, Row } from "react-bootstrap";
import { SelectPicker } from "rsuite";
import Layout from '../../layout/Layout';
import { Link } from "react-router-dom";

const dataType = ['Eugenia', 'Bryan', 'Linda', 'Nancy', 'Lloyd', 'Alice', 'Julia', 'Albert'].map(
    item => ({ label: item, value: item })
);

const RcExchangeReportAdd = () => {
    return (
        <Layout sidebar={true}>
            <div className="page-heading">
                <h3>RC Exchange Report Add</h3>
                <Breadcrumb className="d-none d-sm-none d-md-none d-lg-block">
                    <Breadcrumb.Item >
                        <Link to="/"><i className='bx bx-home-alt me-2 fs-5' ></i> Home</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >
                        <Link to="/rc-exchange-report">RC Exchange Report</Link>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>RC Exchange Report Add</Breadcrumb.Item>
                </Breadcrumb>
            </div>

            <div className="page-content">
                <Form noValidate>
                    <Card className="mb-4 radiustop-0">
                        <Card.Body>
                            <Row>
                                <Col md={4}>
                                    <Form.Label>Vendor Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        className="my-2"
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Vendor Name Field Is Require
                                    </Form.Control.Feedback>
                                </Col>

                                <Col md={4}>
                                    <Form.Label>Select Type</Form.Label>
                                    <SelectPicker
                                        block
                                        cleanable={false}
                                        data={dataType}
                                        searchable={false}
                                        placeholder="Select Type"
                                        className="my-2"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Select Type Field Is Require
                                    </Form.Control.Feedback>
                                </Col>

                                <Col md={4}>
                                    <Form.Label>Limit</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="limit"
                                        className="my-2"
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Limit Field Is Require
                                    </Form.Control.Feedback>
                                </Col>

                                <Col md={4}>
                                    <Form.Label>API</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="api"
                                        className="my-2"
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        API Field Is Require
                                    </Form.Control.Feedback>
                                </Col>
                            </Row>
                        </Card.Body>
                        <Card.Footer className="text-end">
                            <Button variant="primary" className="me-3">Save</Button>
                            <Link to='/rc-exchange-report'>
                                <Button variant="secondary">Cancel</Button>
                            </Link>
                        </Card.Footer>
                    </Card>
                </Form>
            </div>
        </Layout>
    )
}

export default RcExchangeReportAdd